import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '136, 14, 79',
		'primary-dark': '86, 0, 39',
		'accent': '195, 25, 99',
		'accent-plus': '255, 255, 255',
	},
});
